import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const FunctionsFilter = ({ isOpen, onClose, onApply }) => {
  const [filters, setFilters] = useState({
    filterName: []
  });

  const [capabilities, setCapabilities] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetch('https://mvd-cms-api.nawatech.co/api/sma/fallback/get-master-capability')
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 200) {
            const formattedCapabilities = data.data.map((capability) => ({
              value: capability,
              label: capability
            }));
            setCapabilities(formattedCapabilities);
          }
        })
        .catch((error) => {
          console.error('Error fetching capabilities:', error);
        });
    }
  }, [isOpen]);

  const handleInputChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      filterName: selectedOptions
    }));
  };

  const handleClear = () => {
    const clearedFilters = {
      filterName: []
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedFilters = {
      ...filters,
      filterName: filters.filterName.map(option => option.value) 
    };
    onApply(appliedFilters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="functions filter-modal">
        <h2 className="filter-title">Filter</h2>
        <hr className="filter-separator" />
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="filter-form-group">
            <label htmlFor="filter-name">Filter Name</label>
            <Select
              id="filter-name"
              name="filterName"
              isMulti
              options={capabilities}
              value={filters.filterName}
              onChange={handleInputChange}
              placeholder="-- Select Filter Name --"
              className="multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="filter-form-actions">
            <button type="button" className="clear-button" onClick={handleClear}>
              Cancel
            </button>
            <button type="submit" className="apply-button">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FunctionsFilter;
