import React, { useState } from "react";
import uploadIcon from "../assets/uploadIcon.svg";
import FiltersIcon from "../assets/filtersIcon.svg";
import sortIcon from "../assets/sortIcon.svg";
import kebabMenu from "../assets/kebabMenu.svg";
import sampleData from "./sampleData.json"
import FunctionsFilter from "./FunctionsFilter";
import DownloadModal from "./DownloadModal";
import { ReactComponent as DetailsIcon } from "../assets/detailsEye.svg";
import { ReactComponent as EditIcon } from "../assets/editIcon.svg";
import "./Functions.css"; 

const Functions = () => {
  const [data, setData] = useState(sampleData);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

  const handleFilterButtonClick = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleDownloadButtonClick = () => {
    setDownloadModalOpen(!isDownloadModalOpen);
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    handleCloseFilterModal();
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleCloseDownloadModal = () => {
    setDownloadModalOpen(false);
  };

  const handleMenuClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  return (
    <div className="functions-container">
      <div className="functions-header">
        <h1>Functions</h1>
        <button className="upload-button">
          <img src={uploadIcon} className="upload-icon" alt="upload icon" />
          Upload Bulk Functions
        </button>
      </div>
      <div className="functions-buttons">
        <button className="download-button" onClick={handleDownloadButtonClick}>Download</button>
        <button className="filter-button" onClick={handleFilterButtonClick}>
          <img src={FiltersIcon} className="filters-icon" alt="filters icon" />
          Filter
        </button>
      </div>
      <div className="functions-table">
        <table>
          <thead>
            <tr>
              <th>Function Name</th>
              <th>Description</th>
              <th>
                Last Update
                <img
                  src={sortIcon}
                  className="sort-icon"
                  alt="Sort icon"
                  style={{ cursor: "pointer" }}
                />
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.function_name}</td>
                <td>{item.description}</td>
                <td>{item.last_updated}</td>
                <td style={{ position: "relative" }}>
                  <img
                    src={kebabMenu}
                    alt="kebab menu"
                    onClick={() => handleMenuClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                  {activeMenu === index && (
                    <div className="functions-menu">
                      <div className="functions-menu-option">
                        <DetailsIcon className="functions-menu-icon" />
                        View Details
                      </div>
                      <div
                        className="functions-menu-option"
                        style={{ cursor: "pointer" }}
                      >
                        <EditIcon className="functions-menu-icon" />
                        Edit
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <FunctionsFilter isOpen={isFilterModalOpen} onClose={handleCloseFilterModal} onApply={handleFilterApply} />
      <DownloadModal isOpen={isDownloadModalOpen} onClose={handleCloseDownloadModal} />
    </div>
  );
};

export default Functions;
