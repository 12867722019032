import React from "react";
import cancelIcon from "../assets/cancelIcon.svg";
import downloadIcon from "../assets/downloadIcon.svg";
import { FaTimes, FaDownload } from "react-icons/fa"; // Import icons from react-icons

const DownloadModal = ({ isOpen, onClose }) => {
    return (
        <div className={`download-modal ${isOpen ? "open" : ""}`}>
            <div className="download-modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={cancelIcon} alt="cancel icon" onClick={onClose}/>
                </button>
                <h2 className="download-title">Download</h2>
                <hr className="download-separator" />
                <div className="file-download-section">
                    <div className="file-download-item">
                        <span className="file-name">filex.xlsx</span>
                        <button className="download-icon-button">
                            <img src={downloadIcon} alt="download icon" />
                        </button>
                    </div>
                    <div className="file-download-item">
                        <span className="file-name">sample_question.xlsx</span>
                        <button className="download-icon-button">
                            <img src={downloadIcon} alt="download icon" />
                        </button>
                    </div>
                </div>
                <div className="download-all-section">
                    <button className="download-all-button">
                        Download All
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DownloadModal;
